import React, { useState, useEffect } from "react";
import { serviceGetStakeData } from "../../services/Private Sale/privateSaleDataService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type _Props = {
  wallet_address: string;
  closeBtn: Function;
};

const StakeModal = ({ wallet_address, closeBtn }: _Props) => {
  const stakeHeaderList = [
    "Amount",
    "Start Date",
    "Duration",
    "Expected Return",
  ];
  const [isStakeLoaded, setIsStakeLoaded] = useState(false);
  const [offset, setOffset] = useState(0);
  const [itemsPerPage] = useState(20);
  const [stakeData, setStakeData] = useState([]);

  const getStakeData = async () => {
    try {
      let _res = await serviceGetStakeData(
        wallet_address,
        offset,
        itemsPerPage
      );

      if (_res.status == "success") {
        setIsStakeLoaded(true);
        setStakeData(_res.data.rows);
      }
    } catch (e) {
      toast.error("Error while fetching prospects ,please try agian");
    }
  };

  function close() {
    closeBtn();
  }

  function formatDate(date: string) {
    let objectDate = new Date(date);

    let day = objectDate.getDate();
    // 23

    let month = objectDate.getMonth();
    // 8

    let year = objectDate.getFullYear();
    // 2022

    return year + "-" + month + "-" + day;
  }

  useEffect(() => {
    getStakeData();
  }, [isStakeLoaded]);

  return (
    <div
      className="modal fade"
      id="termsAndConditionModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h2 className="modal-title term__head" id="exampleModalLabel">
              Stakes
            </h2>
            <button className="close-button" onClick={() => close()}></button>
          </div>

          <div className="modal-body">
            {
              <table className="table table-striped">
                <thead>
                  <tr>
                    {stakeHeaderList.map((item, index) => (
                      <th>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isStakeLoaded ? (
                    <>
                      {stakeData.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>{item.amount}</td>
                          <td>{formatDate(item.start_date)}</td>
                          <td>{item.duration}</td>
                          <td>{item.expected_beta_reward}</td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <p>No Data Found</p>
                  )}
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeModal;
