import React, { useState, useEffect } from "react";
import AuthCodePage from "../../components/authCodePage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TabView, TabPanel } from "primereact/tabview";
import PrivateSaleWalletInvestors from "./privateSaleWalletInvestors";

function PrivateSaleInvestors() {
  const [statuses] = useState(["1", "0"]);
  const [nftStatus] = useState([1, 0]);
  let [isAuthCodePresent, setAuthCodePresent] = useState(false);
  let [authCode, setAuthCode] = useState("");
  const _authCode = localStorage.getItem("premium_wallet_auth_code");

  function checkAuthCode(_value: string) {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");
      setAuthCodePresent(true);
      window.location.reload();
    } else {
      setAuthCodePresent(false);
      return toast.error("Invalid auth code");
    }
  }

  return (
    <>
      {_authCode ? (
        <div className="container-fluid">
          <div className="card">
            <div className="table-title">
              <h2>Private Sale Investors</h2>
            </div>
            <div className="custom_tabs mt-15">
              <TabView>
                <TabPanel header="Wallet">
                  <PrivateSaleWalletInvestors />
                </TabPanel>

                <TabPanel header="Term Sheets & Bank Transfer">
                  <div className="text-center">
                    <h2>NA</h2>
                  </div>
                </TabPanel>

                <TabPanel header="Manual Transfer (Crypto)">
                  <div className="text-center">
                    <h2>NA</h2>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
          <ToastContainer />
        </div>
      ) : (
        <AuthCodePage
          checkAuthCode={(_value: string) => {
            checkAuthCode(_value);
          }}
        />
      )}
    </>
  );
}

export default PrivateSaleInvestors;
