import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import {
  serviceGetPrivateSaleInvestments,
  serviceSendEarlyAccessInvites,
} from "../../services/Private Sale/privateSaleDataService";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import AuthCodePage from "../../components/authCodePage";
import Config from "../../config";
import StakeModal from "../../components/Private Sale/stakeModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import NftModal from "../../components/Private Sale/nftModal";
import ClaimModal from "../../components/Private Sale/claimModal";
import BadgeModal from "../../components/Private Sale/badgeModal";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";

function OtherContributors() {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    first_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    last_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    total_amount: { value: null, matchMode: FilterMatchMode.EQUALS },
    wallet_address: { value: null, matchMode: FilterMatchMode.CONTAINS },
    earlyAccessStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    l1xInvestment: { value: null, matchMode: FilterMatchMode.EQUALS },
    waitListed: { value: null, matchMode: FilterMatchMode.EQUALS },
    user_nft: { value: null, matchMode: FilterMatchMode.EQUALS },
    claim_token: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [investorList, setInvestorList] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [statuses] = useState(["1", "0"]);
  const [nftStatus] = useState([1, 0]);
  let [isAuthCodePresent, setAuthCodePresent] = useState(false);
  let [authCode, setAuthCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nftModalIsOpen, setNftModalIsOpen] = useState(false);
  const [claimModalIsOpen, setClaimModalIsOpen] = useState(false);
  const [badgeModalIsOpen, setBadgeModalIsOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const [rowClick, setRowClick] = useState(true);
  const [privateSaleInvestment, setPrivateSaleInvestment] = useState("");
  const [isClaimed, setIsClaimed] = useState("");
  const [l1xWalletAddress, setL1xWalletAddress] = useState("");
  const _authCode = localStorage.getItem("premium_wallet_auth_code");

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getSeverity = (status: String) => {
    switch (status) {
      case "0":
        return "danger";

      case "1":
        return "success";
    }
  };

  const getNftSeverity = (status: Number) => {
    switch (status) {
      case 0:
        return "danger";

      case 1:
        return "success";
    }
  };

  function renderValue(_value: String) {
    if (_value) {
      return "YES";
    }
    return "NO";
  }

  function renderNftValue(_value: Number) {
    if (_value) {
      return "YES";
    }
    return "NO";
  }

  function renderOptionValue(_value: String) {
    if (_value == "1") {
      return "Yes";
    } else if (_value == "0") {
      return "No";
    } else {
      return "Select One";
    }
  }

  function renderNftOptionValue(_value: Number) {
    if (_value == 1) {
      return "Yes";
    } else if (_value == 0) {
      return "No";
    } else {
      return "Select One";
    }
  }

  const statusBodyTemplate = (rowData: any) => {
    return (
      <Tag
        value={renderValue(rowData.earlyAccessStatus)}
        severity={rowData.earlyAccessStatus == 1 ? "success" : "danger"}
      />
    );
  };

  const nftBodyTemplate = (rowData: any) => {
    return (
      <Tag
        value={renderNftValue(rowData.user_nft)}
        severity={rowData.user_nft == 1 ? "success" : "danger"}
      />
    );
  };
  const ClaimBodyTemplate = (rowData: any) => {
    return rowData.is_claimed == 0 ? (
      <Button
        className="claim-btn"
        label="Claim"
        onClick={() => openClaimModal(rowData.wallet_address)}
      />
    ) : (
      <Tag value="Claimed" severity="success" />
    );
  };

  const BadgeBodyTemplate = (rowData: any) => {
    return rowData.is_claimed == 0 ? (
      <Button
        className="claim-btn"
        label="Available Badge's"
        onClick={() =>
          openBadgeModal(
            rowData.wallet_address,
            rowData.total_amount,
            rowData.is_claimed,
            rowData.l1x_wallet_address
          )
        }
      />
    ) : (
      <Button
        className="claim-btn"
        label="View Badge's"
        onClick={() =>
          openBadgeModal(
            rowData.wallet_address,
            rowData.total_amount,
            rowData.is_claimed,
            rowData.l1x_wallet_address
          )
        }
      />
    );
  };

  const statusItemTemplate = (option: any) => {
    return (
      <Tag value={renderOptionValue(option)} severity={getSeverity(option)} />
    );
  };

  const waitListItemTemplate = (option: any) => {
    return (
      <Tag value={renderOptionValue(option)} severity={getSeverity(option)} />
    );
  };

  const nftItemTemplate = (option: any) => {
    return (
      <Tag
        value={renderOptionValue(option)}
        severity={getNftSeverity(option)}
      />
    );
  };

  const renderHeader = () => {
    return (
      <React.Fragment>
        <div className="flex justify-content-center">
          <span className="p-input-icon-left">
            <button
              className="dark-btn"
              type="button"
              onClick={() => giveAccess()}
            >
              Give Access
            </button>
          </span>
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </React.Fragment>
    );
  };

  async function giveAccess() {
    await handleEarlyAccessInvite();
  }

  const footer = `In total there are ${
    investorList ? investorList.length : 0
  } investors.`;

  const renderAmountCell = (rowData: any) => {
    return rowData.total_amount ? rowData.total_amount : "-";
  };
  const renderFirstNameCell = (rowData: any) => {
    return rowData.first_name ? rowData.first_name : "-";
  };
  const renderLastNameCell = (rowData: any) => {
    return rowData.last_name ? rowData.last_name : "-";
  };
  const renderEmailCell = (rowData: any) => {
    return rowData.email ? rowData.email : "-";
  };
  const renderWalletAddressCell = (rowData: any) => {
    return rowData.wallet_address ? rowData.wallet_address : "-";
  };

  const renderL1xAllocationCell = (rowData: any) => {
    return rowData.l1xInvestment ? rowData.l1xInvestment : "-";
  };

  const renderAction = (rowData: any) => {
    return (
      <>
        <Tag
          value="View Stakes"
          severity="info"
          onClick={() => openModal(rowData.wallet_address)}
        />
        &nbsp;
        {rowData.user_nft ? (
          <Tag
            value="View NFT"
            severity="warning"
            onClick={() => openNftModal(rowData.wallet_address)}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const renderWaitlistCell = (rowData: any) => {
    return (
      <Tag
        value={renderValue(rowData.waitListed)}
        severity={rowData.waitListed == 1 ? "success" : "danger"}
      />
    );
  };

  const getPrivateSaleInvestorsList = async (_authCode = authCode) => {
    try {
      let _response = await serviceGetPrivateSaleInvestments(_authCode);
      if (_response.status === "success") {
        setInvestorList(_response?.data?.transaction);
        setAuthCodePresent(true);
      }
      if (_response.status === "failure") {
        setAuthCodePresent(false);
      }
    } catch (e: any) {
      return toast.error(e.message);
    }
  };

  async function handleEarlyAccessInvite() {
    try {
      const invites: any[] = [];

      if (selectedInvestors.length == 0) {
        return toast.error("Please select investor");
      }

      (
        selectedInvestors as Array<{ access_key: string; type: string }>
      ).forEach((element) => {
        const _tmpObj: any = {
          access_key: element.access_key,
          type: element.type,
        };

        invites.push(_tmpObj);
      });

      let _data = {
        auth_code: localStorage.getItem("premium_wallet_auth_code"),
        invites: invites,
      };

      let sendInviteResp = await serviceSendEarlyAccessInvites(_data);

      if (sendInviteResp.status === "success") {
        toast.success("Early access saved successfully");
        await getPrivateSaleInvestorsList(
          localStorage.getItem("premium_wallet_auth_code") || ""
        );
        setSelectedInvestors([]);
      } else {
        toast.error("Something went wrong,please try again later");
      }
    } catch (error) {
      toast.error("Something went wrong,please try again later");
    }
  }

  const openModal = async (wallet_address: string) => {
    setWalletAddress(wallet_address);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openNftModal = async (wallet_address: string) => {
    setWalletAddress(wallet_address);
    setNftModalIsOpen(true);
  };

  const closeNftModal = () => {
    setNftModalIsOpen(false);
  };

  const openClaimModal = async (wallet_address: string) => {
    setWalletAddress(wallet_address);
    setClaimModalIsOpen(true);
  };

  const closeClaimModal = () => {
    setClaimModalIsOpen(false);
  };

  const openBadgeModal = async (
    wallet_address: string,
    total_amount: string,
    is_claimed: string,
    l1x_wallet_address: string
  ) => {
    setWalletAddress(wallet_address);
    setPrivateSaleInvestment(total_amount);
    setBadgeModalIsOpen(true);
    setIsClaimed(is_claimed);
    setL1xWalletAddress(l1x_wallet_address);
  };

  const closeBadgeModal = () => {
    setBadgeModalIsOpen(false);
  };

  const reloadBadgeModal = () => {
    setBadgeModalIsOpen(false);
    getPrivateSaleInvestorsList(
      localStorage.getItem("premium_wallet_auth_code") || ""
    );
  };

  useEffect(() => {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");
      getPrivateSaleInvestorsList(
        localStorage.getItem("premium_wallet_auth_code") || ""
      );
    }
  }, [authCode]);

  const statusFilterTemplate = (options: any) => {
    return (
      <Dropdown
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder={renderOptionValue(options.value)}
        className="p-column-filter"
        showClear
      />
    );
  };

  const nftFilterTemplate = (options: any) => {
    return (
      <Dropdown
        options={nftStatus}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={nftItemTemplate}
        placeholder={renderNftOptionValue(options.value)}
        className="p-column-filter"
        showClear
      />
    );
  };

  const waitListFilterTemplate = (options: any) => {
    return (
      <Dropdown
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={waitListItemTemplate}
        placeholder={renderOptionValue(options.value)}
        className="p-column-filter"
        showClear
      />
    );
  };

  function checkAuthCode(_value: string) {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");
      setAuthCodePresent(true);
      window.location.reload();
    } else {
      setAuthCodePresent(false);
      return toast.error("Invalid auth code");
    }
  }

  return (
    <>
      {_authCode ? (
        <div className="container-fluid">
          <div className="card">
            <div className="table-title">
              <h2>Other Contributors</h2>
            </div>
            <div className="custom_tabs mt-15">
              <TabView>
                <TabPanel header="Early Investors + Team">
                  <div className="text-center">
                    <h2>NA</h2>
                  </div>
                </TabPanel>
                <TabPanel header="Referral ">
                  <div className="text-center">
                    <h2>NA</h2>
                  </div>
                </TabPanel>
                <TabPanel header="Other (Private Sale) ">
                  <div className="text-center">
                    <h2>NA</h2>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
          <ToastContainer />
        </div>
      ) : (
        <AuthCodePage
          checkAuthCode={(_value: string) => {
            checkAuthCode(_value);
          }}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal custom-modal"
        overlayClassName="modal-overlay"
      >
        <StakeModal wallet_address={walletAddress} closeBtn={closeModal} />
      </Modal>
      <Modal
        isOpen={nftModalIsOpen}
        onRequestClose={closeNftModal}
        contentLabel="Example Modal"
        className="modal custom-modal"
        overlayClassName="modal-overlay"
      >
        <NftModal wallet_address={walletAddress} closeBtn={closeNftModal} />
      </Modal>
      <Modal
        isOpen={claimModalIsOpen}
        onRequestClose={closeClaimModal}
        contentLabel="Example Modal"
        className="modal custom-modal custom-modal-sm"
        overlayClassName="modal-overlay"
      >
        <ClaimModal wallet_address={walletAddress} closeBtn={closeClaimModal} />
      </Modal>
      <Modal
        isOpen={badgeModalIsOpen}
        onRequestClose={closeBadgeModal}
        contentLabel="Example Modal"
        className="modal custom-modal custom-modal-xs"
        overlayClassName="modal-overlay"
      >
        <BadgeModal
          wallet_address={walletAddress}
          private_sale_investment={privateSaleInvestment}
          is_claimed={isClaimed}
          l1x_wallet_address={l1xWalletAddress}
          reload={reloadBadgeModal}
          closeBtn={closeBadgeModal}
        />
      </Modal>
    </>
  );
}

export default OtherContributors;
