import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import {
  serviceGetPublicSaleBankTransfer,
  serviceSendEarlyAccessInvites,
} from "../../services/Public Sale/publicSaleDataService";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import AuthCodePage from "../../components/authCodePage";

import StakeModal from "../../components/Private Sale/stakeModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import NftModal from "../../components/Private Sale/nftModal";
import PublicClaimModal from "../../components/Public Sale/publicClaimModal";
import PublicBadgeModal from "../../components/Public Sale/publicBadgeModal";
import { Button } from "primereact/button";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PublicSaleBankTransferInvestors() {
  const [bankTransferFilter, setBankTransferFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    total_amount: { value: null, matchMode: FilterMatchMode.EQUALS },
    wallet_address: { value: null, matchMode: FilterMatchMode.CONTAINS },
    earlyAccessStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    l1xInvestment: { value: null, matchMode: FilterMatchMode.EQUALS },
    waitListed: { value: null, matchMode: FilterMatchMode.EQUALS },
    user_nft: { value: null, matchMode: FilterMatchMode.EQUALS },
    claim_token: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [bankTransferList, setBankTransferList] = useState([]);
  const [bankTransferValue, setBankTransferValue] = useState("");

  const [statuses] = useState(["1", "0"]);
  const [nftStatus] = useState([1, 0]);

  let [isAuthCodePresent, setAuthCodePresent] = useState(false);
  let [authCode, setAuthCode] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nftModalIsOpen, setNftModalIsOpen] = useState(false);
  const [claimModalIsOpen, setClaimModalIsOpen] = useState(false);
  const [badgeModalIsOpen, setBadgeModalIsOpen] = useState(false);
  const [email, setEmail] = useState("");

  const [walletAddress, setWalletAddress] = useState("");
  const [selectedInvestors, setSelectedInvestors] = useState([]);
  const [rowClick, setRowClick] = useState(true);
  const [privateSaleInvestment, setPrivateSaleInvestment] = useState("");
  const [isClaimed, setIsClaimed] = useState("");
  const [l1xWalletAddress, setL1xWalletAddress] = useState("");

  const [loading, setLoading] = useState(true);
  const _authCode = localStorage.getItem("premium_wallet_auth_code");

  const onBankTransferFilterChange = (e: any) => {
    const bankValue = e.target.value;
    let _filters = { ...bankTransferFilter };

    _filters["global"].value = bankValue;

    setBankTransferFilter(_filters);
    setBankTransferValue(bankValue);
  };

  const getSeverity = (status: String) => {
    switch (status) {
      case "0":
        return "danger";

      case "1":
        return "success";
    }
  };

  const getNftSeverity = (status: Number) => {
    switch (status) {
      case 0:
        return "danger";

      case 1:
        return "success";
    }
  };

  function renderValue(_value: String) {
    if (_value) {
      return "YES";
    }
    return "NO";
  }

  function renderNftValue(_value: Number) {
    if (_value) {
      return "YES";
    }
    return "NO";
  }

  function renderOptionValue(_value: String) {
    if (_value == "1") {
      return "Yes";
    } else if (_value == "0") {
      return "No";
    } else {
      return "Select One";
    }
  }

  const statusBodyTemplate = (rowData: any) => {
    return (
      <Tag
        value={renderValue(rowData.earlyAccessStatus)}
        severity={rowData.earlyAccessStatus == 1 ? "success" : "danger"}
      />
    );
  };

  const ClaimBodyTemplate = (rowData: any) => {
    return rowData.is_claimed == 0 ? (
      <Button
        className="claim-btn"
        label="Claim"
        onClick={() => openClaimModal(rowData.email)}
      />
    ) : (
      <Tag value="Claimed" severity="success" />
    );
  };

  const BadgeBodyTemplate = (rowData: any) => {
    return rowData.is_claimed == 0 ? (
      <Button
        className="claim-btn"
        label="Available Badge's"
        onClick={() =>
          openBadgeModal(
            rowData.email,
            rowData.wallet_address,
            rowData.total_amount,
            rowData.is_claimed,
            rowData.l1x_wallet_address
          )
        }
      />
    ) : (
      <Button
        className="claim-btn"
        label="View Badge's"
        onClick={() =>
          openBadgeModal(
            rowData.email,
            rowData.wallet_address,
            rowData.total_amount,
            rowData.is_claimed,
            rowData.l1x_wallet_address
          )
        }
      />
    );
  };

  const statusItemTemplate = (option: any) => {
    return (
      <Tag value={renderOptionValue(option)} severity={getSeverity(option)} />
    );
  };

  const waitListItemTemplate = (option: any) => {
    return (
      <Tag value={renderOptionValue(option)} severity={getSeverity(option)} />
    );
  };

  const nftItemTemplate = (option: any) => {
    return (
      <Tag
        value={renderOptionValue(option)}
        severity={getNftSeverity(option)}
      />
    );
  };

  const renderHeaderBankTransfer = () => {
    return (
      <React.Fragment>
        <div className="flex justify-content-center">
          <span className="p-input-icon-left">
            <button
              className="dark-btn"
              type="button"
              onClick={() => giveAccess()}
            >
              Approve Wallet Access
            </button>
          </span>
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              value={bankTransferValue}
              onChange={onBankTransferFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </React.Fragment>
    );
  };

  async function giveAccess() {
    await handleEarlyAccessInvite();
  }

  const BankTransferfooter = `In total there are ${
    bankTransferList ? bankTransferList.length : 0
  } investors.`;

  const renderAmountCell = (rowData: any) => {
    return rowData.total_amount ? rowData.total_amount : "-";
  };

  const renderEmailCell = (rowData: any) => {
    return rowData.email ? rowData.email : "-";
  };

  const renderL1xAllocationCell = (rowData: any) => {
    return rowData.l1xInvestment ? rowData.l1xInvestment : "-";
  };

  const renderWaitlistCell = (rowData: any) => {
    return (
      <Tag
        value={renderValue(rowData.waitListed)}
        severity={rowData.waitListed == 1 ? "success" : "danger"}
      />
    );
  };

  const getPublicSaleBankTransferList = async (_authCode = authCode) => {
    try {
      setLoading(true);
      let _response = await serviceGetPublicSaleBankTransfer(_authCode);
      if (_response.status === "success") {
        setBankTransferList(_response?.data?.transaction);
        setAuthCodePresent(true);
      }
      if (_response.status === "failure") {
        setAuthCodePresent(false);
      }
    } catch (e: any) {
      return toast.error(e.message);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  async function handleEarlyAccessInvite() {
    try {
      const invites: any[] = [];

      if (selectedInvestors.length == 0) {
        return toast.error("Please select investor");
      }

      (
        selectedInvestors as Array<{ access_key: string; type: string }>
      ).forEach((element) => {
        const _tmpObj: any = {
          access_key: element.access_key,
          type: element.type,
        };

        invites.push(_tmpObj);
      });

      let _data = {
        auth_code: localStorage.getItem("premium_wallet_auth_code"),
        invites: invites,
      };

      let sendInviteResp = await serviceSendEarlyAccessInvites(_data);

      if (sendInviteResp.status === "success") {
        toast.success("Early access saved successfully");

        await getPublicSaleBankTransferList(
          localStorage.getItem("premium_wallet_auth_code") || ""
        );

        setSelectedInvestors([]);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong,please try again later");
    }
  }

  const openModal = async (wallet_address: string) => {
    setWalletAddress(wallet_address);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openNftModal = async (wallet_address: string) => {
    setWalletAddress(wallet_address);
    setNftModalIsOpen(true);
  };

  const closeNftModal = () => {
    setNftModalIsOpen(false);
  };

  const openClaimModal = async (email: string) => {
    setEmail(email);
    setClaimModalIsOpen(true);
  };

  const closeClaimModal = () => {
    setClaimModalIsOpen(false);
  };

  const openBadgeModal = async (
    email: string,
    wallet_address: string,
    total_amount: string,
    is_claimed: string,
    l1x_wallet_address: string
  ) => {
    setEmail(email);
    setWalletAddress(wallet_address);
    setPrivateSaleInvestment(total_amount);
    setBadgeModalIsOpen(true);
    setIsClaimed(is_claimed);
    setL1xWalletAddress(l1x_wallet_address);
  };

  const closeBadgeModal = () => {
    setBadgeModalIsOpen(false);
  };

  const reloadBadgeModal = () => {
    setBadgeModalIsOpen(false);

    getPublicSaleBankTransferList(
      localStorage.getItem("premium_wallet_auth_code") || ""
    );
  };

  useEffect(() => {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");

      getPublicSaleBankTransferList(
        localStorage.getItem("premium_wallet_auth_code") || ""
      );
    }
  }, [authCode]);

  const statusFilterTemplate = (options: any) => {
    return (
      <Dropdown
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder={renderOptionValue(options.value)}
        className="p-column-filter"
        showClear
      />
    );
  };

  const waitListFilterTemplate = (options: any) => {
    return (
      <Dropdown
        options={statuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={waitListItemTemplate}
        placeholder={renderOptionValue(options.value)}
        className="p-column-filter"
        showClear
      />
    );
  };

  function checkAuthCode(_value: string) {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");
      setAuthCodePresent(true);
      window.location.reload();
    } else {
      setAuthCodePresent(false);
      return toast.error("Invalid auth code");
    }
  }

  return (
    <>
      {_authCode ? (
        <>
          {loading ? (
            <div
              className="loading-container"
              style={{ marginTop: "150px", alignItems: "center" }}
            >
              <FontAwesomeIcon icon={faSpinner} spin size="3x" />
              <p>Loading...</p>
            </div>
          ) : (
            <DataTable
              value={bankTransferList}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              tableStyle={{ minWidth: "50rem", padding: "20px" }}
              header={renderHeaderBankTransfer}
              footer={BankTransferfooter}
              filters={bankTransferFilter}
              filterDisplay="menu"
              className="customTable"
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedInvestors}
              onSelectionChange={(e: any) => setSelectedInvestors(e.value)}
              dataKey="email"
              selectionPageOnly={true}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column
                body={renderEmailCell}
                field="email"
                header="Email"
                sortable
                style={{ width: "25%" }}
              ></Column>

              <Column
                body={renderAmountCell}
                field="total_amount"
                header="Total Investment"
                sortable
                style={{ width: "25%" }}
              ></Column>
              <Column
                body={renderL1xAllocationCell}
                field="l1xInvestment"
                header="L1X Allocation"
                sortable
                style={{ width: "25%" }}
              ></Column>
              <Column
                body={statusBodyTemplate}
                field="earlyAccessStatus"
                header="Early Access Status"
                filterMenuStyle={{ width: "14rem" }}
                style={{ minWidth: "12rem" }}
                filter
                filterElement={statusFilterTemplate}
                showFilterMatchModes={false}
              ></Column>
              <Column
                body={renderWaitlistCell}
                field="waitListed"
                header="NotifyOnWhitelist"
                filterMenuStyle={{ width: "14rem" }}
                style={{ minWidth: "12rem" }}
                filter
                filterElement={waitListFilterTemplate}
                showFilterMatchModes={false}
              ></Column>

              <Column
                body={BadgeBodyTemplate}
                field="badge"
                header="Badge"
                filterMenuStyle={{ width: "10rem" }}
                style={{ minWidth: "9rem" }}
              ></Column>
              <Column
                body={ClaimBodyTemplate}
                field="claim_token"
                header="Claim Status"
                filterMenuStyle={{ width: "14rem" }}
                style={{ minWidth: "12rem" }}
              ></Column>
              {/* <Column
                      body={renderAction}
                      field="action"
                      header="Action"
                    ></Column> */}
            </DataTable>
          )}

          <ToastContainer />
        </>
      ) : (
        <AuthCodePage
          checkAuthCode={(_value: string) => {
            checkAuthCode(_value);
          }}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal custom-modal"
        overlayClassName="modal-overlay"
      >
        <StakeModal wallet_address={walletAddress} closeBtn={closeModal} />
      </Modal>
      <Modal
        isOpen={nftModalIsOpen}
        onRequestClose={closeNftModal}
        contentLabel="Example Modal"
        className="modal custom-modal"
        overlayClassName="modal-overlay"
      >
        <NftModal wallet_address={walletAddress} closeBtn={closeNftModal} />
      </Modal>
      <Modal
        isOpen={claimModalIsOpen}
        onRequestClose={closeClaimModal}
        contentLabel="Example Modal"
        className="modal custom-modal custom-modal-sm"
        overlayClassName="modal-overlay"
      >
        <PublicClaimModal
          email={email}
          closeBtn={closeClaimModal}
          getList={getPublicSaleBankTransferList}
        />
      </Modal>
      <Modal
        isOpen={badgeModalIsOpen}
        onRequestClose={closeBadgeModal}
        contentLabel="Example Modal"
        className="modal custom-modal custom-modal-xs"
        overlayClassName="modal-overlay"
      >
        <PublicBadgeModal
          email={email}
          wallet_address={walletAddress}
          private_sale_investment={privateSaleInvestment}
          is_claimed={isClaimed}
          l1x_wallet_address={l1xWalletAddress}
          reload={reloadBadgeModal}
          closeBtn={closeBadgeModal}
        />
      </Modal>
    </>
  );
}

export default PublicSaleBankTransferInvestors;
