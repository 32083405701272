import React, { useState, useEffect } from "react";
import {
  servicePublicClaimToken,
  serviceGetPublicSaleWalletInvestments,
} from "../../services/Public Sale/publicSaleDataService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ethers } from "ethers";

type _Props = {
  claimData: any;
  email: string;
  closeBtn: Function;
  getList: Function;
};

const PublicClaimForm = ({ claimData, email, closeBtn, getList }: _Props) => {
  const authCode = localStorage.getItem("premium_wallet_auth_code");

  const [formData, setFormData] = useState({
    email: email,
    l1x_wallet_address: "",
    type: 2, // Internally Set to 1 for Private Sale and 2 for Public Sale
    investment_amount_in_usd: claimData.totalInvestment,
    l1x_allocation: claimData.l1xAllocated,
    l1x_referrel_bonus: claimData.bonusAllocation,
    l1x_total_allocation: claimData.totalInvestment,
    auth_code: authCode,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function isValidAddress(l1x_wallet_address: any) {
    try {
      let valid = ethers.isAddress(l1x_wallet_address);
      console.log("Address:", l1x_wallet_address, "Valid:", valid);
      return valid;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();

      if (!formData.l1x_wallet_address) {
        return toast.error("Please enter an L1X wallet address");
      } else if (!isValidAddress(formData.l1x_wallet_address)) {
        return toast.error("Invalid L1X wallet address");
      } else {
        // Continue with your code for valid address

        let claimFormData = {
          email: email,
          l1xWalletAddress: formData.l1x_wallet_address,
          authCode: authCode,
        };

        let _response = await servicePublicClaimToken(claimFormData);

        if (_response.status == "success") {
          toast.success("Token's claimed successfully!");

          setTimeout(function () {
            close();

            getList();
          }, 2000);
        } else {
          return toast.error("Something went wrong, please try again");
        }
      }
    } catch (e) {
      return toast.error("Something went wrong, please try again");
    }
  };

  function close() {
    closeBtn();
  }

  useEffect(() => {
    // Update the state variable with the new value of propToWatch
    setFormData({
      email: email,
      l1x_wallet_address: "",
      type: 2, // Internally Set to 1 for Private Sale and 2 for Public Sale
      investment_amount_in_usd: claimData.totalInvestment,
      l1x_allocation: claimData.l1xAllocated,
      l1x_referrel_bonus: claimData.bonusAllocation,
      l1x_total_allocation: claimData.totalInvestment,
      auth_code: "",
    });
  }, [claimData]);

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <div>
          <label className="form-label" htmlFor="email">
            Email Address
          </label>
          <input
            type="text"
            className="form-input"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div>
          <label className="form-label" htmlFor="l1x_wallet_address">
            L1X Wallet Address
          </label>
          <input
            type="text"
            className="form-input"
            id="l1x_wallet_address"
            name="l1x_wallet_address"
            value={formData.l1x_wallet_address}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label className="form-label" htmlFor="investment_amount_in_usd">
            Investment Amount In USD
          </label>
          <input
            type="text"
            className="form-input"
            id="investment_amount_in_usd"
            name="investment_amount_in_usd"
            value={formData.investment_amount_in_usd}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div>
          <label className="form-label" htmlFor="l1x_allocation">
            L1X Allocation
          </label>
          <input
            type="text"
            className="form-input"
            id="l1x_allocation"
            name="l1x_allocation"
            value={formData.l1x_allocation}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div>
          <label className="form-label" htmlFor="l1x_referrel_bonus">
            L1X Referrel Bonus
          </label>
          <input
            type="text"
            className="form-input"
            id="l1x_referrel_bonus"
            name="l1x_referrel_bonus"
            value={formData.l1x_referrel_bonus}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div>
          <label className="form-label" htmlFor="l1x_total_allocation">
            L1X Total Allocation
          </label>
          <input
            type="text"
            className="form-input"
            id="l1x_total_allocation"
            name="l1x_total_allocation"
            value={formData.l1x_total_allocation}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="text-center">
          <button className="form-button" type="submit">
            Claim
          </button>
          <button className="close-button" onClick={() => close()}></button>
        </div>
      </form>
    </div>
  );
};

export default PublicClaimForm;
