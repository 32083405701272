import {
  getData,
  postRawJsonData,
  postFile,
  postClaimData,
  getClaimData,
} from "../../Api/index";

async function serviceGetPublicSaleWalletInvestments(_authCode) {
  let _response = await getData(
    `/api/public_sales/public_sale_wallet_investments?auth_code=${_authCode}&sale_type=2`
  );
  return _response;
}

async function serviceGetPublicSaleBankTransfer(_authCode) {
  let _response = await getData(
    `/api/public_sales/public_sale_bank_transfer_investment?auth_code=${_authCode}`
  );
  return _response;
}

async function serviceGetPublicSaleManualInvestments(_authCode) {
  let _response = await getData(
    `/api/public_sales/public_sale_wallet_investments?auth_code=${_authCode}&sale_type=1`
  );
  return _response;
}

async function serviceGetPublicSaleMoonpayInvestments(_authCode) {
  let _response = await getData(
    `/api/public_sales/public_sale_wallet_investments?auth_code=${_authCode}&sale_type=3`
  );
  return _response;
}

async function serviceGetStakeData(_walletAddress, offset = 1, limit = 5) {
  let _response = await getData(
    `/api/private_sales/prospect_staking_list?wallet_address=${_walletAddress}&offset=${offset}&limit=${limit}`
  );

  return _response;
}

async function serviceSendEarlyAccessInvites(data) {
  let _response = await postRawJsonData(
    `/api/private_sales/import_early_access_invites`,
    data
  );
  return _response;
}

async function serviceGetWaitList(_authCode) {
  let _response = await getData(
    `/api/private_sales/early_access_explicit_request_list?auth_code=${_authCode}`
  );
  return _response;
}
async function serviceFileUpload(data) {
  let _response = await postFile(
    `/api/private_sales/import_wallet_early_access_invites`,
    data
  );
  return _response;
}
async function serviceGetNftDetails(_wallet_address, _authCode) {
  let _response = await getData(
    `/api/private_sales/get_user_nft_details?wallet_address=${_wallet_address}&auth_code=${_authCode}`
  );
  return _response;
}

async function servicePublicGetClaimStatus(email, auth_code) {
  let _response = await getData(
    `/api/public_sales/check_claim_status?email=${email}&auth_code=${auth_code}`
  );
  return _response;
}

async function servicePublicClaimToken(data) {
  let _response = await postRawJsonData(
    `/api/public_sales/claim_allocation`,
    data
  );
  return _response;
}

async function serviceGetAvailablePublicBadgeDatails(_email, _authCode) {
  let _response = await getData(
    `/api/public_sales/public_sale_view_badges?email=${_email}&auth_code=${_authCode}`
  );
  return _response;
}

async function serviceGetClaimPublicBadgeDatails(
  _l1x_wallet_address,
  _authCode
) {
  let _response = await getData(
    `/api/public_sales/get_user_claim_badges?auth_code=${_authCode}&l1x_wallet_address=${_l1x_wallet_address}`
  );
  return _response;
}

async function serviceIssuePublicBadge(data) {
  let _response = await postRawJsonData(
    `/api/public_sales/issue_l1x_badge`,
    data
  );
  return _response;
}

export {
  serviceGetPublicSaleWalletInvestments,
  serviceGetPublicSaleManualInvestments,
  serviceGetPublicSaleMoonpayInvestments,
  serviceGetStakeData,
  serviceSendEarlyAccessInvites,
  serviceGetWaitList,
  serviceFileUpload,
  serviceGetNftDetails,
  servicePublicGetClaimStatus,
  serviceGetAvailablePublicBadgeDatails,
  serviceGetClaimPublicBadgeDatails,
  serviceIssuePublicBadge,
  servicePublicClaimToken,
  serviceGetPublicSaleBankTransfer,
};
