import React, { useState, useEffect } from "react";

import AuthCodePage from "../../components/authCodePage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TabView, TabPanel } from "primereact/tabview";
import PublicSaleWalletInvestors from "./publicSaleWalletInvestors";
import PublicSaleBankTransferInvestors from "./publicSaleBankTransferInvestors";
import PublicSaleManualInvestors from "./publicSaleManualInvestors";
import PublicSaleMoonpayInvestors from "./publicSaleMoonpayInvestors";

function PublicSaleInvestors() {
  let [isAuthCodePresent, setAuthCodePresent] = useState(false);
  let [authCode, setAuthCode] = useState("");

  const _authCode = localStorage.getItem("premium_wallet_auth_code");

  function checkAuthCode(_value: string) {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");
      setAuthCodePresent(true);
      window.location.reload();
    } else {
      setAuthCodePresent(false);
      return toast.error("Invalid auth code");
    }
  }

  return (
    <>
      {_authCode ? (
        <div className="container-fluid">
          <div className="card">
            <div className="table-title">
              <h2>Public Sale Investors</h2>
            </div>
            <div className="custom_tabs mt-15">
              <TabView>
                <TabPanel header="Wallet">
                  <PublicSaleWalletInvestors />
                </TabPanel>

                <TabPanel header="Term Sheets & Bank Transfer">
                  <PublicSaleBankTransferInvestors />
                </TabPanel>

                <TabPanel header="Manual Transfer (Crypto)">
                  <PublicSaleManualInvestors />
                </TabPanel>

                <TabPanel header="Moonpay Transfer (Crypto)">
                  <PublicSaleMoonpayInvestors />
                </TabPanel>
              </TabView>
            </div>
          </div>
          <ToastContainer />
        </div>
      ) : (
        <AuthCodePage
          checkAuthCode={(_value: string) => {
            checkAuthCode(_value);
          }}
        />
      )}
    </>
  );
}

export default PublicSaleInvestors;
