import React, { useState, useEffect } from "react";
import { serviceClaimToken } from "../../services/Private Sale/privateSaleDataService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ethers } from "ethers";

type _Props = {
  claimData: any;
  walletAddress: string;
};

const ClaimForm = ({ claimData, walletAddress }: _Props) => {
  const authCode = localStorage.getItem("premium_wallet_auth_code");

  const [formData, setFormData] = useState({
    reference_wallet_address: walletAddress,
    l1x_wallet_address: "",
    type: 1, // Internally Set to 1 for Private Sale and 2 for Public Sale
    investment_amount_in_usd: claimData.privateSaleInvestment,
    l1x_allocation: claimData.l1xAllocated,
    l1x_referrel_bonus: claimData.bonusAllocation,
    l1x_total_allocation: claimData.totalInvestment,
    auth_code: authCode,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function isValidAddress(l1x_wallet_address: any) {
    try {
      let valid = ethers.isAddress(l1x_wallet_address);
      console.log("Address:", l1x_wallet_address, "Valid:", valid);
      return valid;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();

      if (!formData.l1x_wallet_address) {
        return toast.error("Please enter an L1X wallet address");
      } else if (!isValidAddress(formData.l1x_wallet_address)) {
        return toast.error("Invalid L1X wallet address");
      } else {
        let claimFormData = {
          walletAddress: walletAddress,
          l1xWalletAddress: formData.l1x_wallet_address,
          authCode: authCode,
        };

        let _response = await serviceClaimToken(claimFormData);

        if (_response.status == "success") {
          toast.success("Token's claimed successfully!");

          setTimeout(function () {
            return window.location.reload();
          }, 2000);
        } else {
          return toast.error("Something went wrong, please try again");
        }
      }
    } catch (e) {
      return toast.error("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    // Update the state variable with the new value of propToWatch
    setFormData({
      reference_wallet_address: walletAddress,
      l1x_wallet_address: "",
      type: 1, // Internally Set to 1 for Private Sale and 2 for Public Sale
      investment_amount_in_usd: claimData.privateSaleInvestment,
      l1x_allocation: claimData.l1xAllocated,
      l1x_referrel_bonus: claimData.bonusAllocation,
      l1x_total_allocation: claimData.totalInvestment,
      auth_code: "",
    });
  }, [claimData]);

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <div>
          <label className="form-label" htmlFor="reference_wallet_address">
            Metamask Address
          </label>
          <input
            type="text"
            className="form-input"
            id="reference_wallet_address"
            name="reference_wallet_address"
            value={formData.reference_wallet_address}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div>
          <label className="form-label" htmlFor="l1x_wallet_address">
            L1X Wallet Address
          </label>
          <input
            type="text"
            className="form-input"
            id="l1x_wallet_address"
            name="l1x_wallet_address"
            value={formData.l1x_wallet_address}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label className="form-label" htmlFor="investment_amount_in_usd">
            Investment Amount In USD
          </label>
          <input
            type="text"
            className="form-input"
            id="investment_amount_in_usd"
            name="investment_amount_in_usd"
            value={formData.investment_amount_in_usd}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div>
          <label className="form-label" htmlFor="l1x_allocation">
            L1X Allocation
          </label>
          <input
            type="text"
            className="form-input"
            id="l1x_allocation"
            name="l1x_allocation"
            value={formData.l1x_allocation}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div>
          <label className="form-label" htmlFor="l1x_referrel_bonus">
            L1X Referrel Bonus
          </label>
          <input
            type="text"
            className="form-input"
            id="l1x_referrel_bonus"
            name="l1x_referrel_bonus"
            value={formData.l1x_referrel_bonus}
            onChange={handleInputChange}
            disabled
          />
        </div>

        <div>
          <label className="form-label" htmlFor="l1x_total_allocation">
            L1X Total Allocation
          </label>
          <input
            type="text"
            className="form-input"
            id="l1x_total_allocation"
            name="l1x_total_allocation"
            value={formData.l1x_total_allocation}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="text-center">
          <button className="form-button" type="submit">
            Claim
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClaimForm;
