import React, { useState, useEffect } from "react";
import { servicePublicGetClaimStatus } from "../../services/Public Sale/publicSaleDataService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicClaimForm from "./publicClaimForm";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type _Props = {
  email: string;
  closeBtn: Function;
  getList: Function;
};

const ClaimModal = ({ email, closeBtn, getList }: _Props) => {
  const stakeHeaderList = ["Collection Address", "Token Id"];
  const [isClaimDataLoaded, setIsClaimDataLoaded] = useState(false);
  const [claimData, setClaimData] = useState([]);
  const [loading, setLoading] = useState(true);
  const authCode = localStorage.getItem("premium_wallet_auth_code");

  const getClaimStatus = async () => {
    try {
      setLoading(true);
      let _res = await servicePublicGetClaimStatus(email, authCode);

      if (_res.status == "success") {
        setIsClaimDataLoaded(true);
        setClaimData(_res.data);
      }
    } catch (e) {
      toast.error("Error while fetching prospects ,please try agian");
    } finally {
      setLoading(false); // Stop loader
    }
  };

  function close() {
    closeBtn();
  }

  function getInvestorList() {
    getList();
  }

  useEffect(() => {
    getClaimStatus();
  }, [isClaimDataLoaded]);

  return (
    <div
      className="modal fade"
      id="termsAndConditionModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header border-0">
            <div className="text-center">
              <h2 className="modal-title term__head" id="exampleModalLabel">
                Claim Token
              </h2>
            </div>
            <button className="close-button" onClick={() => close()}></button>
          </div>

          <div className="modal-body">
            {loading ? (
              <div className="loading-container">
                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                <p>Loading...</p>
              </div>
            ) : (
              <PublicClaimForm
                claimData={claimData}
                email={email}
                closeBtn={close}
                getList={getInvestorList}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimModal;
