import React, { useState, useEffect } from "react";
import {
  serviceGetAvailableBadgeDatails,
  serviceGetClaimBadgeDatails,
  serviceIssueBadge,
} from "../../services/Private Sale/privateSaleDataService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "primereact/button";
import iAmAnOgBadge from "./../../Assets/Images/badges/iAmAnOgBadge.png";
import perrToPeerBadge from "./../../Assets/Images/badges/perrToPeerBadge.png";
import publicSaleBadge from "./../../Assets/Images/badges/publicSaleBadge.png";
import earlyAccessBadge from "./../../Assets/Images/badges/earlyAccessBadge.png";
import Config from "../../config";
type _Props = {
  wallet_address: string;
  private_sale_investment: string;
  is_claimed: string;
  l1x_wallet_address: string;
  reload: Function;
  closeBtn: Function;
};

const BadgeModal = ({
  wallet_address,
  private_sale_investment,
  is_claimed,
  l1x_wallet_address,
  reload,
  closeBtn,
}: _Props) => {
  const [isBadgeDetailsLoaded, setIsBadgeDetailsLoaded] = useState(false);
  const [availableBadgeData, setAvailableBadgeData] = useState([]);
  const badges = Config.BADGES;

  function getBadgeType(_name: string) {
    if (_name == badges[0]) {
      return 1;
    }
    if (_name == badges[1]) {
      return 2;
    }
    if (_name == badges[2]) {
      return 3;
    }
    if (_name == badges[3]) {
      return 4;
    }
  }

  function getBadgeName(_type: string) {
    if (_type == "1") {
      return badges[0];
    } else if (_type == "2") {
      return badges[1];
    } else if (_type == "3") {
      return badges[2];
    } else if (_type == "4") {
      return badges[3];
    } else {
      return "false";
    }
  }

  function getBadgeImage(_name: string) {
    if (_name == badges[0]) {
      return iAmAnOgBadge;
    }
    if (_name == badges[1]) {
      return earlyAccessBadge;
    }
    if (_name == badges[2]) {
      return perrToPeerBadge;
    }
    if (_name == badges[3]) {
      return publicSaleBadge;
    }
  }

  const getAvailableBadgeDetails = async () => {
    try {
      let _res = await serviceGetAvailableBadgeDatails(
        wallet_address,
        private_sale_investment,
        localStorage.getItem("premium_wallet_auth_code")
      );
      if (_res.status == "success") {
        setIsBadgeDetailsLoaded(true);
        setAvailableBadgeData(_res.data);
      }
    } catch (e) {
      toast.error("Something went wrong, please try again");
    }
  };

  const getClaimBadgeDetails = async () => {
    try {
      let _res = await serviceGetClaimBadgeDatails(
        l1x_wallet_address,
        localStorage.getItem("premium_wallet_auth_code")
      );
      if (_res.status == "success") {
        setIsBadgeDetailsLoaded(true);
        setAvailableBadgeData(_res.data);
      }
    } catch (e) {
      toast.error("Something went wrong, please try again");
    }
  };

  const handleIssueBadge = async (_type: string) => {
    try {
      let issueBadgeData = {
        auth_code: localStorage.getItem("premium_wallet_auth_code"),
        l1x_wallet_address: l1x_wallet_address,
        type: _type,
      };

      let _response = await serviceIssueBadge(issueBadgeData);

      if (_response.status == "success") {
        toast.success("Badge issued successfully");
        reload();
      } else {
        return toast.error("Something went wrong, please try again");
      }
    } catch (e) {
      return toast.error("Something went wrong, please try again");
    }
  };

  function close() {
    closeBtn();
  }

  const issueBadge = async (_type: string) => {
    try {
      await handleIssueBadge(_type);
    } catch (e) {
      return toast.error("Something went wrong, please try again");
    }
  };

  const renderBadge = (_image: any) => {
    let image = _image.label;

    if (_image.label == _image.type) {
      image = iAmAnOgBadge;
    }
    if (_image.label == _image.type) {
      image = earlyAccessBadge;
    }
    if (_image.label == _image.type) {
      image = perrToPeerBadge;
    }
    if (_image.label == _image.type) {
      image = publicSaleBadge;
    }

    return (
      <>
        {image == "" ? (
          <>
            <p className="text-center">{getBadgeName(_image.type)}</p>
            <div className="blur-image">
              <img
                src={getBadgeImage(getBadgeName(_image.type)) || ""}
                alt="badge"
              />
            </div>
            {is_claimed == "1" ? (
              <Button
                className="claim-btn"
                label="Issue"
                onClick={() => issueBadge(_image.type)}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <p className="text-center">{getBadgeName(_image.type)}</p>
            <img
              src={getBadgeImage(getBadgeName(_image.type)) || ""}
              alt="badge"
            />
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (is_claimed == "1") {
      getClaimBadgeDetails();
    } else {
      getAvailableBadgeDetails();
    }
  }, [is_claimed]);

  return (
    <div
      className="modal fade"
      id="termsAndConditionModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h2 className="modal-title term__head" id="exampleModalLabel">
              Badge's
            </h2>
            <button className="close-button" onClick={() => close()}></button>
          </div>

          <div className="modal-body">
            <ul className="badge-list">
              {isBadgeDetailsLoaded ? (
                <>
                  {availableBadgeData.map((item: any, index: number) => (
                    <>
                      <li>{renderBadge(availableBadgeData[index])}</li>
                    </>
                  ))}
                </>
              ) : (
                <p>No Data Found</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgeModal;
