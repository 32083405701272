import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import {
  serviceGetWaitList,
  serviceFileUpload,
} from "../../services/Private Sale/privateSaleDataService";
import AuthCodePage from "../../components/authCodePage";
import Config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileUpload } from "primereact/fileupload";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function WaitList() {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [loading, setLoading] = useState(true);
  const [waitList, setWaitList] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  let [isAuthCodePresent, setAuthCodePresent] = useState(false);
  let [authCode, setAuthCode] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const _authCode = localStorage.getItem("premium_wallet_auth_code");

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <React.Fragment>
        <div className="flex justify-content-center">
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </React.Fragment>
    );
  };

  const footer = `In total there are ${waitList ? waitList.length : 0} users.`;

  const renderEmailCell = (rowData: any) => {
    return rowData.email ? rowData.email : "-";
  };

  const getWaitList = async () => {
    try {
      setLoading(true);
      let _response = await serviceGetWaitList(
        localStorage.getItem("premium_wallet_auth_code")
      );
      if (_response.status === "success") {
        setWaitList(_response?.data?.explicitList);
        setAuthCodePresent(true);
      }
      if (_response.status === "failure") {
        setAuthCodePresent(false);
      }
    } catch (e: any) {
      return toast.error(e.message);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  useEffect(() => {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");
      getWaitList();
    }
  }, [authCode]);

  function checkAuthCode(_value: string) {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");
      setAuthCodePresent(true);
      window.location.reload();
    } else {
      setAuthCodePresent(false);
      return toast.error("Invalid auth code");
    }
  }

  const onFileSelect = (event: any) => {
    const selected = event.files[0];
    if (selected && selected.type === "text/csv") {
      if (selected.size > 2 * 1024 * 1024) {
        setSelectedFile(null);
        return toast.error("File size exceeds 2MB limit.");
      }
      setSelectedFile(selected);
    } else {
      setSelectedFile(null);
      return toast.error("Please select a CSV file.");
    }
  };

  const customUpload = async () => {
    try {
      if (selectedFile) {
        let data = {
          file: selectedFile,
          auth_code: localStorage.getItem("premium_wallet_auth_code"),
        };

        let _response = await serviceFileUpload(data);
        if (_response.status == "success") {
          getWaitList();
          setSelectedFile(null);
          return toast.success("File uploaded successfully");
        } else {
          return toast.error("Error while uploading file,please try again");
        }
      }
    } catch (e) {
      return toast.error("Error while uploading file,please try again");
    }
  };
  return (
    <>
      {_authCode ? (
        <div className="container-fluid">
          <div className="card">
            <div className="waitlist_container">
              <div className="table-title mb-15">
                <h2>Upload CSV</h2>
              </div>
              <div>
                <FileUpload
                  name="demo"
                  customUpload
                  uploadHandler={customUpload}
                  onSelect={onFileSelect}
                  mode="advanced"
                  accept=".csv"
                  emptyTemplate={
                    <p className="m-0">
                      Drag and drop files to here to upload.
                    </p>
                  }
                />
              </div>
              <div className="table-title mb-15">
                <h2>WaitList</h2>
              </div>
              <div>
                {loading ? (
                  <div
                    className="loading-container"
                    style={{ marginTop: "150px", alignItems: "center" }}
                  >
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <DataTable
                    value={waitList}
                    paginator
                    rows={25}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem", padding: "20px" }}
                    header={renderHeader}
                    footer={footer}
                    filters={filters}
                    className="customTable"
                  >
                    <Column
                      body={renderEmailCell}
                      field="email"
                      header="Email"
                      sortable
                      style={{ width: "25%" }}
                    ></Column>
                  </DataTable>
                )}
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      ) : (
        <AuthCodePage
          checkAuthCode={(_value: string) => {
            checkAuthCode(_value);
          }}
        />
      )}
    </>
  );
}

export default WaitList;
