import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./styles.scss";
import { Route, Routes } from "react-router-dom";
import PrivateSaleInvestors from "./Pages/Private Sale/privateSaleInvestors";
import WaitList from "./Pages/Waitlist/waitList";
import Navbar from "./components/navBar";
import OtherContributors from "./Pages/Other Contributors/otherContributors";
import PublicSaleInvestors from "./Pages/Public Sale/publicSaleInvestors";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<PrivateSaleInvestors />} />
        <Route
          path="/private_sale_investors"
          element={<PrivateSaleInvestors />}
        />
        <Route path="/other_contributors" element={<OtherContributors />} />
        <Route
          path="/public_sale_investors"
          element={<PublicSaleInvestors />}
        />
        <Route path="/waitlist" element={<WaitList />} />
      </Routes>
    </>
  );
}

export default App;
